import React from "react";
import { ContentBuilder } from "@modules/contentBuilder/index";
import { SingleHero, SingleHeader, SingleCTA } from "@modules/singles/index";
import { SEO } from "@common";
import { Section, Container } from "@ui";

/* eslint react/prop-types: 0 */

const BlogSingle = ({ data }) => {
  const { uid, title, summary, date, heroImage, categories, cta, body } = data;
  const {
    publication,
    publicationMedium,
    link,
    image,
    overrideHeadingText,
    overrideLinkText,
  } = cta;
  const hasCTA = publication || link.url;

  return (
    <>
      <SEO title={title} description={summary.text} image={heroImage} />
      <SingleHero image={heroImage} />

      <Section size="sm">
        <Container size="cb" className="relative">
          <SingleHeader
            uid={uid}
            backUrl="/blog/"
            backText="all posts"
            title={title}
            date={date}
            categories={categories}
          />
        </Container>
      </Section>

      <Section hasPaddingTop={false}>
        <ContentBuilder blocks={body} wrapInContainer />

        {hasCTA && (
          <Container size="cb" className="relative mt-10 md:mt-12">
            <SingleCTA
              link={link}
              image={image}
              publication={publication}
              overrideLinkText={overrideLinkText}
              overrideHeadingText={overrideHeadingText}
              medium={publicationMedium}
            />
          </Container>
        )}
      </Section>
    </>
  );
};

export default BlogSingle;
