import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import BlogSingle from "../../pageTemplates/BlogSingle";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveSlices from "../dataResolvers/resolveSlices";
import resolveImageData from "../dataResolvers/resolveImageData";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicBlogPosts }) => {
  const { uid, data } = prismicBlogPosts;
  const { prefixed, unPrefixed } = resolveByPrefix(data, ["cta_"]);
  const { cta } = prefixed;
  const { image_group, categories, display_date, body, ...rest } = unPrefixed;

  const resolved = {
    data: {
      uid,
      date: display_date,
      categories: categories.map(({ category }) => ({
        title: category.toLowerCase(),
        id: category
          .toLowerCase()
          .split(" ")
          .join("-"),
      })),
      heroImage: resolveImageData(image_group[0]),
      body: resolveSlices(body),
      cta: {
        ...cta,
        image: resolveImageData({ image: cta.image }),
      },
      ...rest,
    },
  };

  return keysToCamel(resolved);
};

const BlogSingleQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <BlogSingle {...data} location={location} />;
};

export default BlogSingleQuery;

export const query = graphql`
  query($uid: String!) {
    prismicBlogPosts(uid: { eq: $uid }) {
      uid
      data {
        title
        display_date(formatString: "MMM DD, YYYY")
        summary {
          text
        }
        image_group {
          focal_point_x
          focal_point_y
          image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
        categories {
          category
        }
        cta_publication
        cta_publication_medium
        cta_link {
          url
          target
        }
        cta_override_heading_text
        cta_override_link_text
        cta_image {
          url
          alt
          dimensions {
            height
            width
          }
        }
        body {
          ... on PrismicBlogPostsBodyCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicBlogPostsBodyLargeCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicBlogPostsBodyImages {
            id
            slice_type
            items {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              focal_point_y
              focal_point_x
              filter
              caption {
                html
              }
            }
          }
          ... on PrismicBlogPostsBodyTestimonial {
            id
            slice_type
            primary {
              text {
                html
              }
              person
              role
              photo {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicBlogPostsBodyCurriculumCta {
            id
            slice_type
            primary {
              heading
              description {
                html
              }
              material {
                id
                uid
                url
                document {
                  ... on PrismicLessons {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                      cor_question {
                        id
                        document {
                          ... on PrismicCorQuestions {
                            id
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAssessments {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                      cor_question {
                        id
                        document {
                          ... on PrismicCorQuestions {
                            id
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicCollections {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogPostsBodyGeneralCta {
            id
            slice_type
            primary {
              heading
              cta_text
              cta_link {
                url
                target
              }
            }
          }
          ... on PrismicBlogPostsBodyVideoEmbed {
            id
            slice_type
            primary {
              video {
                document {
                  ... on PrismicVideos {
                    data {
                      title
                      youtube_video_id
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogPostsBodyDecorativeImage {
            id
            slice_type
            primary {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
